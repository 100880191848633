import '../scss/royal-vision'

$(document).on('click', '#music .downloadable', function(e) {
  e.stopPropagation()
})

$(document).on('click', '#music .cover', function() {
  var album = $(this).next('.album')
  album.toggleClass('collapsed')
  $('.album').not(album).addClass('collapsed')
})
